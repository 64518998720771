<template>
  <b-row class="app-login mx-0 bg-white full-vh">
    <b-col
      cols="12"
      lg="6"
      class="d-flex justify-content-center align-items-center flex-column justify-content-between py-3 py-lg-5"
    >
      <div>
        <a
          href="https://uello.com.br"
          target="blank"
          rel="noopener noreferrer"
          class="d-block"
          title="Uello"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="246" height="60" viewBox="0 0 246 60" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#e63b4f"><path d="M216.03 53.357c-12.983 0-23.545-10.51-23.545-23.43 0-12.922 10.562-23.433 23.545-23.433 12.983 0 23.546 10.51 23.546 23.432 0 12.92-10.563 23.431-23.546 23.431m0-53.144c-16.462 0-29.855 13.329-29.855 29.713 0 16.383 13.393 29.712 29.855 29.712 16.464 0 29.857-13.329 29.857-29.712 0-16.384-13.393-29.713-29.857-29.713M97.242 22.569v-5.377H79.456v26.68h17.786v-5.394h-11.3v-5.396h8.903v-5.257h-8.902V22.57zm19.692-6.59v26.68h16.516v-5.515h-10.12V15.979zm35.573 1.213v26.68h16.516v-5.516h-10.12V17.192zM21.548 41.95c.99.985 2.208 1.765 3.621 2.315 1.4.543 2.99.82 4.73.82 1.737 0 3.328-.277 4.73-.82 1.412-.55 2.632-1.328 3.627-2.313.996-.986 1.77-2.17 2.297-3.52.523-1.34.789-2.812.789-4.377v-15.65h-6.458v15.65c0 .853-.119 1.627-.351 2.304-.229.653-.555 1.212-.973 1.66-.42.45-.922.79-1.535 1.04-1.232.505-3.022.507-4.254.001a4.11 4.11 0 01-1.518-1.044 4.781 4.781 0 01-.97-1.674c-.235-.675-.353-1.45-.353-2.303V18.404h-6.457v15.651c0 1.568.265 3.04.789 4.376a10.084 10.084 0 002.286 3.519"/><path d="M29.908 53.357c-12.983 0-23.546-10.51-23.546-23.43 0-12.922 10.563-23.433 23.546-23.433 12.983 0 23.546 10.51 23.546 23.432 0 12.92-10.563 23.431-23.546 23.431m0-53.144C13.444.213.05 13.542.05 29.926c0 16.383 13.393 29.712 29.857 29.712 16.462 0 29.855-13.329 29.855-29.712C59.763 13.542 46.37.213 29.908.213"/></g></svg>
        </a>
        <p class="text-primary font-weight-bold text-center mt-3">{{ $t('appTitle') }}</p>
      </div>

      <b-form
        @submit.prevent="handleSubmit"
        class="form-container d-flex flex-column"
        autocomplete="off"
      >
        <b-form-input
          id="email"
          name="uello-email"
          type="email"
          autocomplete="off"
          v-model="form.email"
          required
          :placeholder="$t('form.placeholders.email')"
        ></b-form-input>

        <b-form-input
          id="password"
          name="uello-password"
          :type="inputType"
          class="mt-4"
          autocomplete="off"
          v-model="form.password"
          required
          :placeholder="$t('form.placeholders.password')"
        >
        </b-form-input>

        <div class="iconEye" @click.prevent.stop="toggleInputType">
          <b-icon-eye-slash v-if="inputType === 'password'"></b-icon-eye-slash>
          <b-icon-eye v-else></b-icon-eye>
        </div>

        <b-form-checkbox
          v-model="rememberEmail"
          name="uello-remember-email"
          size="sm"
          value="remember"
          unchecked-value="not_remember"
        >
          <span class="text-dark-darken cursor-pointer">{{
            $t('form.placeholders.rememberEmail')
          }}</span>
        </b-form-checkbox>

        <b-button
          variant="primary"
          type="submit"
          class="mt-4 font-weight-bold"
          :disabled="getLoading"
        >
          <span v-if="!getLoading">{{ $t('general.enterButton') }}</span>
          <b-spinner v-else variant="white" class="btn-spin"></b-spinner>
        </b-button>
        <b-button class="mt-4 font-weight-bold" variant="outline-info" @click="showModal = true">
          {{ $t('form.labels.forgetPassword') }}
        </b-button>
      </b-form>
      <p class="text-dark-darken">
        <a
          href="https://uello.com.br"
          target="blank"
          rel="noopener noreferrer"
          class="text-dark-darken"
          >{{ $t('general.loginFooterText') }} <b class="text-primary">Uello</b></a
        >
      </p>
      <p class="text-muted pull-left">
        <small>
          Idioma:
          <a href="#" @click.prevent.stop="setLanguage(`es_ES`)">Espanhol(ES) </a>
          <a href="#" @click.prevent.stop="setLanguage(`pt_BR`)">Português(BR) </a>
        </small>
      </p>
      <b-modal
        class="forgot-password-modal"
        hide-footer
        title="Esqueci a senha"
        v-model="showModal"
      >
        <b-form @submit.prevent="sendForgotPassword">
          <b-form-input
            id="email-input"
            name="new-email"
            type="email"
            v-model="forgotPassword.email"
            :placeholder="$t('form.placeholders.email')"
            autocomplete="off"
            required
          ></b-form-input>

          <b-button type="submit" variant="primary" class="w-100 mt-3" :disabled="loading">
            <span v-if="!loading">{{ $t('form.buttons.sendButton') }}</span>
            <b-spinner v-else variant="white" class="btn-spin" small></b-spinner>
          </b-button>
        </b-form>
      </b-modal>
    </b-col>
    <b-col cols="6" class="px-0 d-none d-lg-block">
      <login-slider />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { safeStorage } from '@/utils';
import api from '@/services/api';
import * as types from '@/store/types';
import LoginSlider from './components/LoginSlider.vue';

export default {
  name: 'uello-app-login',
  data() {
    return {
      inputType: 'password',
      rememberEmail: 'not_remember',
      form: {
        email: '',
        password: '',
      },
      showModal: false,
      forgotPassword: {
        email: '',
      },
      loading: false,
    };
  },
  created() {
    if (safeStorage.getItem('rememberEmail')) {
      this.form.email = safeStorage.getItem('user').email;
      this.rememberEmail = 'remember';
      return;
    }
    safeStorage.clear();
  },
  components: {
    LoginSlider,
  },
  computed: {
    ...mapGetters({
      getLoading: types.GETTER_AUTH_LOADING,
    }),
  },
  methods: {
    ...mapActions({
      setAuth: types.ACTION_LOGIN_REQUEST,
    }),
    handleSubmit() {
      this.setAuth(this.form);
      this.setUserInfo();
    },
    setLanguage(locale) {
      this.$i18n.locale = locale;
    },
    setUserInfo() {
      if (this.rememberEmail === 'remember') {
        safeStorage.setItem('rememberEmail', JSON.stringify({ rememberEmail: this.rememberEmail }));
      } else {
        safeStorage.clear();
      }
      safeStorage.setItem('user', JSON.stringify({ email: this.form.email }));
    },
    login() {
      this.$store.dispatch(types.ACTION_STATE, { name: 'Michelon souza' });
    },
    toggleInputType() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    },
    async sendForgotPassword() {
      this.loading = true;

      try {
        await api.post('/users/recovery', this.forgotPassword);

        this.showModal = false;
        this.$toast.success(
          this.$t('messages.sendForgotPasswordSuccess', {
            email: this.forgotPassword.email,
          })
        );
        this.forgotPassword.email = '';
      } catch (error) {
        this.$toast.error(
          this.$t('messages.sendForgotPasswordError', { email: this.forgotPassword.email })
        );
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
header.modal-header {
  padding: 0.5rem 1rem !important;

  h5 {
    font-size: 1.3rem;
  }
}

.form-container {
  width: 500px;
  max-width: 90%;

  .btn-spin {
    width: 1.3rem;
    height: 1.3rem;
  }
}
.iconEye {
  position: relative;
  left: 470px;
  top: -31px;
  cursor: pointer;
  width: 18px;
  border: none;
  outline: none;
}
</style>
