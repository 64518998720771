<template>
  <div
    class="login-slider flex-1 d-flex justify-content-center align-items-center h-100 px-3 flex-column"
    :style="{ backgroundColor: bgColor }"
  >
    <transition name="fade" mode="out-in">
      <div class="slide" v-if="activeSlider === 0">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img class="slide-img" :src="require(`@/assets/images/print-0.png`)" alt="Slide" />

          <!-- <p class="mt-3 px-3 text-center text-white">{{ slides[0].text }}</p> -->
        </div>
      </div>
      <div class="slide" v-if="activeSlider === 1">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img class="slide-img" :src="require(`@/assets/images/print-1.png`)" alt="Slide" />

          <!-- <p class="mt-3 px-3 text-center text-white">{{ slides[1].text }}</p> -->
        </div>
      </div>
      <div class="slide" v-if="activeSlider === 2">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img class="slide-img" :src="require(`@/assets/images/print-2.png`)" alt="Slide" />

          <!-- <p class="mt-3 px-3 text-center text-dark">{{ slides[2].text }}</p> -->
        </div>
      </div>
    </transition>

    <div class="mt-3 d-flex justify-content-center align-items-center">
      <div
        class="slide-dot"
        v-for="dot in slides.length"
        :key="`dot-${dot}`"
        :style="{ background: dot - 1 === activeSlider ? 'var(--primary)' : 'var(--dark-darken)' }"
      ></div>
    </div>
  </div>
</template>

<script>
import { transparentize } from 'polished';

export default {
  name: 'login-slider',
  data() {
    return {
      activeSlider: 0,
      timeout: 5000,
      interval: null,
      slides: [
        {
          id: 0,
          text:
            'Michelon 1 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat veniam quos praesentium adipisci placeat nobis illum eveniet at natus asperiores ipsam, ducimus, vitae voluptas corrupti autem unde facere sunt nulla.',
          color: '#e63b4f',
        },
        {
          id: 1,
          text:
            'Rafa 2 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat veniam quos praesentium adipisci placeat nobis illum eveniet at natus asperiores ipsam, ducimus, vitae voluptas corrupti autem unde facere sunt nulla.',
          color: '#0188af',
        },
        {
          id: 2,
          text:
            'Rodrigo 3 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat veniam quos praesentium adipisci placeat nobis illum eveniet at natus asperiores ipsam, ducimus, vitae voluptas corrupti autem unde facere sunt nulla.',
          color: '#1cca42',
        },
      ],
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      const newSlide = this.activeSlider + 1;
      if (newSlide > this.slides.length - 1) {
        this.activeSlider = 0;
      } else {
        this.activeSlider = newSlide;
      }
    }, this.timeout);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    bgColor() {
      return transparentize(0.5, this.slides[this.activeSlider].color);
    },
  },
};
</script>

<style lang="scss">
.login-slider {
  transition: all 300ms ease;

  .slide-img {
    width: 500px;
    height: auto;
  }

  .slide-dot {
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--dark-darken);
    transition: background 300ms ease;

    & + .slide-dot {
      margin-left: 15px;
    }
  }
}
</style>
